import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

async function initApp() {

  if (environment.production) {
    enableProdMode();
  }
  else{
    console.log('environment: ', environment.type);
  }

  const appModuleContainer = await import('./app/app.module');

  platformBrowserDynamic()
    .bootstrapModule(appModuleContainer.AppModule)
    .catch((err) => console.error(err));
}

initApp();
